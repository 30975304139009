<template>

  <Header />

  <main>
    <div class="container">
      <div class="left-section">
        <div class="sub-nav">
          <router-link to="/account/settings">Settings</router-link>
          <router-link to="/account/usage-and-billing">Usage &amp; billing</router-link>
          <router-link to="/account/connected-apps">Connected apps</router-link>
          <router-link to="/account/api-keys">API keys</router-link>
          <router-link to="/account/plans">Plans</router-link>
        </div>
        <div class="extra-left-content" v-if="$route.name === 'Plans'">
          <h5>All plans include:</h5>
          <ul class="checked-list">
            <li>Unlimited Patches (automated workflows)</li>
            <li>No-code automated workflow builder</li>
            <li>Discord, Telegram, email and webhook integrations</li>
          </ul>
        </div>
      </div>
      <div class="right-section">
        <template v-if="isUserDataLoading || isBootstrapDataLoading">
          <div class="loading-icon-container">
            <LoadingIcon />
          </div>
        </template>
        <template v-else>
          <router-view />
        </template>
      </div>
    </div>
  </main>

  <Footer />

</template>

<script>

  import { mapState } from 'vuex'

  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      Header,
      Footer,
      LoadingIcon,
    },
    computed: {
      ...mapState('user', ['isUserDataLoading']),
      ...mapState('app', ['isBootstrapDataLoading']),
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

  main
    @apply bg-white

    min-height: calc(100% - 128px)

  .container
    @apply p-4
    @apply mx-auto

    @apply flex
    @apply gap-8
    @apply flex-col

    +breakpoint(sm)
      @apply py-16
      @apply gap-6
      @apply flex-row

  .left-section
    @apply flex
    @apply gap-8
    @apply flex-col
    @apply flex-shrink-0

    +breakpoint(sm)
      @apply w-64
      @apply gap-16

  .sub-nav
    @apply p-6
    @apply rounded
    @apply space-y-6
    @apply bg-purple-100

    @apply flex
    @apply flex-col

    a:not(.router-link-exact-active)
      @apply text-gray-1000

  .extra-left-content
    @apply p-6
    @apply w-full

    @apply bg-gray-200

    @apply border
    @apply rounded
    @apply border-gray-400

  .right-section
    @apply mx-auto
    @apply flex-grow

    max-width: 53rem

</style>
